
















/* global FB fb */
import { globalConfig } from "@/v2/core";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
} from "@vue/composition-api";
import {
  AtomButton,
  AtomButtonSizeEnum,
  AtomText,
  AtomTextColorEnum,
} from "@/v2/new-design-system";
import { startWhatsAppOnboarding } from "@/v2/repo/inbox/start-whatsapp-onboarding";
import { fetchWhatsappOnboarding } from "@/v2/repo/inbox/fetch-whatsapp-onboarding";
import { t } from "@/i18n";

const css = bemBuilder("inbox-whatsapp-signup");

export default defineComponent({
  name: "InboxWhatsAppSignup",
  components: {
    AtomButton,
    AtomText,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    businessName: {
      type: String,
      required: true,
    },
    tenantName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const displaySuccessMessage = ref(false);

    function initFb() {
      // @ts-ignore
      window.fbAsyncInit = function () {
        // @ts-ignore
        FB._initialized = false;
        FB.init({
          appId: globalConfig.inboxMetaAppId,
          cookie: true,
          xfbml: true,
          version: "v20.0",
        });
      };
    }

    function loadFbSdk() {
      const id = "facebook-jssdk";

      if (document.getElementById(id)) {
        return;
      }

      let script = document.createElement("script") as HTMLScriptElement;
      script.id = id;
      script.src = "https://connect.facebook.net/en_US/sdk.js";

      let addedScript = document.getElementsByTagName(
        "script"
      )[0] as HTMLScriptElement;
      addedScript.parentNode?.insertBefore(script, addedScript);
    }

    async function isOnboarding() {
      const response = await fetchWhatsappOnboarding(props.businessId);

      if (response) {
        displaySuccessMessage.value = true;
      }
    }

    onBeforeMount(async () => {
      // Once the user connect through the modal, the success message will be shown
      // By commenting this line it won't check if the business is already onboarded upon page load
      // and will always show the button to start embedded signup
      // isOnboarding();
    });

    async function connected(accessToken: string) {
      await startWhatsAppOnboarding(
        props.businessId,
        props.businessName,
        accessToken,
        props.tenantName
      );

      displaySuccessMessage.value = true;
    }

    function launchWhatsAppSignup() {
      FB.login(
        function (response) {
          const token = response?.authResponse?.accessToken;

          if (token) {
            connected(token);
          }
        },
        {
          config_id: globalConfig.inboxWhatsAppSignupConfigId,
          response_type: "code",
          override_default_response_type: true,
        }
      );
    }

    onMounted(() => {
      initFb();
      loadFbSdk();

      // @ts-ignore
      window.fbAsyncInit();
    });

    return {
      css,
      t,
      launchWhatsAppSignup,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
      displaySuccessMessage,
    };
  },
});
