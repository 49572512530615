










import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  getCurrentInstance,
  PropType,
  ref,
} from "@vue/composition-api";
import { MolIconButton, MolIconButtonTypeEnum } from "@/v2/new-design-system";
import { inboxTrack } from "../track";
import { formatLinkForChannel } from "@/v2/util";
import { EventEnum } from "@/v2/enum";
import { t } from "@/i18n";
import { IInboxChannel } from "../domain/channel";

const css = bemBuilder("inbox-send-ordering-link-button");

export default defineComponent({
  name: "InboxSendOrderingLinkButton",
  components: {
    MolIconButton,
  },
  props: {
    onSendOrderingLink: {
      type: Function as PropType<(text: string) => void>,
      required: true,
    },
    channel: {
      type: String as PropType<IInboxChannel>,
      required: true,
    },
    orderingLink: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const vm = getCurrentInstance();
    const isSendProductModalVisible = ref(false);

    function onClick() {
      const message = formatLinkForChannel(props.orderingLink, props.channel);
      props.onSendOrderingLink(message);

      inboxTrack({
        // @ts-ignore
        analytics: vm?.proxy.$analytics,
        eventName: EventEnum.CLICKED_ON_BUTTON,
        additionalData: {
          label: "Send ordering link",
        },
      });
    }
    return {
      css,
      t,
      onClick,
      isSendProductModalVisible,
      MolIconButtonTypeEnum,
    };
  },
});
