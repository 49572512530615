var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.css()},[(_vm.isLoadingSetup)?_c('AtomLoading',{attrs:{"floating":true}}):_vm._e(),_c('div',{class:_vm.css('header')},[_c('AtomText',{attrs:{"type":_vm.AtomTextTypeEnum.H1}},[_vm._v(" "+_vm._s(_vm.t("module.inbox.module_title"))+" ")]),(_vm.isServiceAvailable)?_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.t('module.inbox.settings_button_tooltip'),
        classes: 'new-tooltip',
      }),expression:"{\n        content: t('module.inbox.settings_button_tooltip'),\n        classes: 'new-tooltip',\n      }"}],class:_vm.css('settings'),attrs:{"to":{
        name: _vm.IInboxRouteEnum.SETTINGS,
        params: {
          businessId: _vm.businessId,
        },
      }}},[_c('AtomIcon',{attrs:{"name":"settings"}})],1):_vm._e()],1),(_vm.isServiceAvailable)?_c('div',{class:_vm.css('wrapper')},[(_vm.isInitialLoading)?_c('AtomLoading',{attrs:{"floating":true}}):_vm._e(),_c('div',{key:'list-of-messages',ref:"conversationsWrapperElement",class:_vm.css('list')},[_c('div',{ref:"conversationsListElement"},[(_vm.isLoadingMoreConversations)?_c('AtomLoading',{attrs:{"inline":true}}):_vm._e(),(!_vm.isInitialLoading)?_c('InboxConversationList',{attrs:{"conversations":_vm.conversations,"business-id":_vm.businessId}}):_vm._e()],1)]),(!_vm.isInitialLoading)?_c('div',{key:'conversation-chat',class:_vm.css('conversation')},[(_vm.conversations.length > 0)?_c('router-view',{key:_vm.id}):_vm._e(),(_vm.conversations.length === 0 && _vm.businessSetup)?_c('InboxEmptyInbox',{attrs:{"business-id":_vm.businessId,"has-whatsapp":_vm.businessSetup.isWhatsAppEnabled,"has-instagram":_vm.businessSetup.isInstagramEnabled}}):_vm._e()],1):_vm._e()],1):_c('div',{class:_vm.css('country-blocked')},[_c('AtomText',{attrs:{"type":_vm.AtomTextTypeEnum.SUBTITLE}},[_vm._v(" "+_vm._s(_vm.t("module.inbox.country_blocked_message"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }