import { httpInbox } from "../http-inbox";
import {
  IAxiosErrorResponse,
  IRepoInboxErrorResponse,
  RepoInboxError,
  RepoInboxErrorEnum,
} from "../error-response";
import { AxiosResponse } from "axios";

export class FetchWhatsappOnboardingError extends RepoInboxError {}

export type IFetchWhatsappOnboardingResponse = {
  businessId: string;
  businessName: string;
  requestedBy: string;
};

export type IFetchWhatsappOnboardingApiResponse =
  IFetchWhatsappOnboardingResponse;

export async function fetchWhatsappOnboarding(
  businessId: string
): Promise<IFetchWhatsappOnboardingResponse | undefined> {
  let response: AxiosResponse<IFetchWhatsappOnboardingApiResponse>;

  try {
    response = await httpInbox.get(`/whatsapp/start-onboarding/${businessId}`);
    return response.data;
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const response: IAxiosErrorResponse = e.response;

    if (response.status === 404) {
      return;
    }

    const error: IRepoInboxErrorResponse = {
      code: RepoInboxErrorEnum.UNKNOWN,
      message: "Unexpected error while fetching a WhatsApp onboarding details.",
      request: { businessId },
      response: response.data,
    };

    throw new FetchWhatsappOnboardingError(error);
  }
}
