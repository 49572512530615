







































import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { IInboxChannel } from "../domain/channel";
import {
  AtomAvatar,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomTextTagEnum,
  AtomGraphic,
  AtomPulsatingCircle,
  AtomLiveTimeAgo,
} from "@/v2/new-design-system";
import { Location } from "vue-router";
import { IInboxRouteEnum } from "../inbox-route.enum";
import { parsePhoneNumber, getNumberFrom } from "awesome-phonenumber";

const css = bemBuilder("inbox-conversation-card");

export default defineComponent({
  name: "InboxConversationCard",
  components: {
    AtomAvatar,
    AtomText,
    AtomGraphic,
    AtomPulsatingCircle,
    AtomLiveTimeAgo,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    channel: {
      type: String as PropType<IInboxChannel>,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    lastMessageAt: {
      type: Date,
      required: true,
    },
    hasUnreadMessage: {
      type: Boolean,
      default: false,
    },
    imageLink: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const chatRoute = computed<Location>(() => ({
      name: IInboxRouteEnum.CHAT,
      params: {
        businessId: props.businessId,
        id: props.id,
      },
    }));

    const channelGraphic = computed<string>(() => {
      if (props.channel === IInboxChannel.WHATSAPP) {
        return "whatsapp";
      }

      return "instagram-v2";
    });

    const formattedTitle = computed(() => {
      const phoneNumber = parsePhoneNumber(`+${props.title}`);

      if (phoneNumber.valid) {
        return getNumberFrom(phoneNumber, "international").number;
      }

      return props.title;
    });

    return {
      css,
      chatRoute,
      formattedTitle,
      channelGraphic,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomTextTagEnum,
    };
  },
});
