import { httpInbox } from "../http-inbox";
import {
  IAxiosErrorResponse,
  RepoInboxError,
  RepoInboxErrorEnum,
} from "../error-response";

export class FetchTemplatesError extends RepoInboxError {}

export interface IFetchTemplate {
  id: string;
  content: string;
  language: string;
}

export async function fetchTemplates(
  businessId: string
): Promise<Array<IFetchTemplate>> {
  let apiResponse: { data: Array<IFetchTemplate> };

  try {
    apiResponse = await httpInbox.get(`/templates/business/${businessId}`);
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const response: IAxiosErrorResponse = e.response;

    throw new FetchTemplatesError({
      code: RepoInboxErrorEnum.UNKNOWN,
      message: "The request to fetch the business templates has failed.",
      request: { businessId },
      response: response.data,
    });
  }

  return apiResponse.data;
}
