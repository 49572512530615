













import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomTextTagEnum,
  AtomDate,
  AtomDateTypeEnum,
} from "@/v2/new-design-system";

const css = bemBuilder("inbox-order-card-status-line");

export default defineComponent({
  name: "InboxOrderCardStatusLine",
  components: {
    AtomText,
    AtomDate,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    processedAt: {
      type: String,
      required: true,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomTextTagEnum,
      AtomDateTypeEnum,
    };
  },
});
