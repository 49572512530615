













import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import { AtomIcon, AtomLink, AtomLinkTypeEnum } from "@/v2/new-design-system";
import { t } from "@/i18n";

const css = bemBuilder("inbox-message-video");

export default defineComponent({
  name: "InboxMessageVideo",
  components: {
    AtomIcon,
    AtomLink,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      css,
      t,
      AtomLinkTypeEnum,
    };
  },
});
