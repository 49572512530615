










import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";

const css = bemBuilder("inbox-message-image");

export default defineComponent({
  name: "InboxMessageImage",
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      css,
    };
  },
});
