import { ref } from "@vue/composition-api";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useWindowSize() {
  const listeners: Set<() => void> = new Set();
  const width = ref(window?.innerWidth ?? 0);
  const height = ref(window?.innerHeight ?? 0);

  const updateWindowSize = (): void => {
    width.value = window.innerWidth;
    height.value = window.innerHeight;
  };

  function onWindowResize(callback: (...args: any) => void): void {
    listeners.add(callback);
    window.addEventListener("resize", callback);
  }

  onWindowResize(updateWindowSize);

  function offAllWindowResize(): void {
    listeners.forEach((listener) =>
      window.removeEventListener("resize", listener)
    );
  }

  return {
    width,
    height,
    onWindowResize,
    offAllWindowResize,
  };
}
