import { each, makeFactory } from "factory.ts";
import * as faker from "faker";

export const FetchTemplatesApiResponseFactory = makeFactory<ITemplate>({
  id: each(() => faker.datatype.uuid()),
  content: each(() => faker.lorem.sentence(30, 20)),
  language: "en",
});

interface ITemplate {
  id: string;
  content: string;
  language: string;
}
