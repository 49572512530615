








































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  PropType,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import { InboxCompose } from "../compose";
import { InboxComposeLocked } from "../compose-locked";
import { InboxMessage } from "../message";
import { IInboxMessage } from "../domain/message";
import { IInboxChannel } from "../domain/channel";
import { IInboxAuthorType } from "../domain/author-type";
import {
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
  AtomTextTagEnum,
  AtomLoading,
} from "@/v2/new-design-system";
import { t, tc } from "@/i18n";
import dayjs from "dayjs";
import { sortAscBy, groupBy } from "@/v2/util";

const css = bemBuilder("inbox-chat");

export default defineComponent({
  name: "InboxChat",
  components: {
    InboxCompose,
    InboxMessage,
    AtomText,
    AtomLoading,
    InboxComposeLocked,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    orderingLink: {
      type: String,
      required: true,
    },
    channel: {
      type: String as PropType<IInboxChannel>,
      required: true,
    },
    contactPhoto: {
      type: String,
      default: "",
    },
    businessLogo: {
      type: String,
      default: "",
    },
    lastMessageReceivedAt: {
      type: Date,
      required: true,
    },
    areAllMessagesLoaded: {
      type: Boolean,
      default: false,
    },
    messages: {
      type: Array as PropType<IInboxMessage[]>,
      default: () => [],
    },
    onLoadMoreMessages: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onSendMessage: {
      type: Function as PropType<(message: string) => void>,
      required: true,
    },
    onSendMessageTemplate: {
      type: Function as PropType<(templateId: string) => void>,
      required: true,
    },
  },
  setup(props) {
    const secondsToExpire = ref(0);
    const intervalId = ref<number>();
    const withSmoothScroll = ref(false);
    const messagesElement = ref() as Ref<HTMLDivElement>;
    const isLoadingMessages = ref(false);

    watch(
      () => props.messages,
      () => {
        const isNextToTheBottom =
          messagesElement.value.scrollHeight -
            messagesElement.value.scrollTop -
            40 <
          messagesElement.value.clientHeight;

        if (isNextToTheBottom) {
          withSmoothScroll.value = true;
          scrollToBottom(0);
          setTimeout(() => (withSmoothScroll.value = false), 200);
        }

        isLoadingMessages.value = false;
      },
      { deep: true }
    );

    const totalGracePeriodInSeconds = computed(() => {
      const aDayInSeconds = 60 * 60 * 24;

      if (props.channel === IInboxChannel.WHATSAPP) {
        return aDayInSeconds;
      }

      return aDayInSeconds * 7;
    });

    const isWindowMessagingOpen = computed(() => {
      return secondsToExpire.value > 0;
    });

    const refreshExpireTime = () => {
      const date = dayjs(props.lastMessageReceivedAt);
      const diff = dayjs().diff(date, "second");

      secondsToExpire.value = totalGracePeriodInSeconds.value - diff;
    };

    function scrollToBottom(waitMs: number) {
      setTimeout(() => {
        messagesElement.value.scrollTop = messagesElement.value.scrollHeight;
      }, waitMs);
    }

    function getPhoto(authorType: IInboxAuthorType) {
      if (authorType === IInboxAuthorType.CONTACT) {
        return props.contactPhoto;
      }

      if (authorType === IInboxAuthorType.AGENT) {
        return props.businessLogo;
      }

      return "";
    }

    const cutoffLabel = computed(() => {
      const oneDay = 60 * 60 * 24;
      const oneHour = 60 * 60;
      const oneMinute = 60;

      if (secondsToExpire.value < oneMinute) {
        return t("module.inbox.chat.less_than_a_minute_cutoff");
      }

      if (secondsToExpire.value < oneHour) {
        const minutes = Math.floor(secondsToExpire.value / oneMinute);
        return tc("module.inbox.chat.cutoff_in_minutes", minutes, { minutes });
      }

      if (secondsToExpire.value < oneDay) {
        const hours = Math.floor(secondsToExpire.value / oneHour);
        return tc("module.inbox.chat.cutoff_in_hours", hours, { hours });
      }

      const days = Math.floor(secondsToExpire.value / oneDay);
      return tc("module.inbox.chat.cutoff_in_days", days, { days });
    });

    async function handleInfinityScroll() {
      if (
        messagesElement.value.scrollTop < 250 &&
        !isLoadingMessages.value &&
        !props.areAllMessagesLoaded
      ) {
        isLoadingMessages.value = true;
        props.onLoadMoreMessages();
      }
    }

    onBeforeMount(() => {
      scrollToBottom(10);
    });

    onMounted(() => {
      refreshExpireTime();
      intervalId.value = window.setInterval(refreshExpireTime, 1000);
      messagesElement.value.addEventListener("scroll", handleInfinityScroll);
    });

    onBeforeUnmount(() => {
      if (intervalId.value) clearInterval(intervalId.value);
      messagesElement.value.removeEventListener("scroll", handleInfinityScroll);
    });

    const groupedMessages = computed(() => {
      if (!props.messages) return {};

      const messages = sortAscBy(props.messages, "createdAt");

      return groupBy(messages, (a: IInboxMessage) =>
        dayjs(a.createdAt).format("dddd, DD MMMM YYYY")
      );
    });

    return {
      css,
      IInboxAuthorType,
      cutoffLabel,
      messagesElement,
      groupedMessages,
      isLoadingMessages,
      isWindowMessagingOpen,
      secondsToExpire,
      getPhoto,
      withSmoothScroll,
      AtomTextColorEnum,
      AtomTextTypeEnum,
      AtomTextTagEnum,
    };
  },
});
