
























import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, ref } from "@vue/composition-api";
import {
  AtomModal,
  MolIconButton,
  MolIconButtonTypeEnum,
} from "@/v2/new-design-system";
import { t } from "@/i18n";
import { InboxSelectMessageTemplate } from "../select-message-template";

const css = bemBuilder("inbox-send-message-template");

export default defineComponent({
  name: "InboxSendMessageTemplate",
  components: {
    AtomModal,
    MolIconButton,
    InboxSelectMessageTemplate,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    onSend: {
      type: Function as PropType<(templateId: string) => void>,
      required: true,
    },
  },
  setup(props) {
    const isSendMessageTemplateVisible = ref(false);

    function onSelected(templateId: string) {
      isSendMessageTemplateVisible.value = false;
      props.onSend(templateId);
    }

    return {
      css,
      t,
      isSendMessageTemplateVisible,
      onSelected,
      MolIconButtonTypeEnum,
    };
  },
});
