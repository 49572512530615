












































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomGraphic,
  AtomLink,
  AtomLinkTypeEnum,
  AtomLinkSizeEnum,
  MolGuideLink,
  MolGuideLinkArticleEnum,
} from "@/v2/new-design-system";
import { t } from "@/i18n";
import { IInboxRouteEnum } from "../inbox-route.enum";

const css = bemBuilder("inbox-empty-inbox");

export default defineComponent({
  name: "InboxEmptyInbox",
  components: {
    AtomText,
    AtomLink,
    MolGuideLink,
    AtomGraphic,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    hasWhatsapp: {
      type: Boolean,
      required: true,
    },
    hasInstagram: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    return {
      css,
      t,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      MolGuideLinkArticleEnum,
      AtomLinkTypeEnum,
      AtomLinkSizeEnum,
      IInboxRouteEnum,
    };
  },
});
