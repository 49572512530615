











































import { MolLoadingZone, MolSearchBox } from "@/v2/new-design-system";
import { fetchActiveMenu } from "@/v2/repo/fetch-active-menu";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  onMounted,
  ref,
  PropType,
  computed,
} from "@vue/composition-api";
import Money from "@/components/helpers/Money.vue";
// @ts-ignore
import Menus from "@/api/menus";
import { flatten, pluck } from "ramda";
import { t } from "@/i18n";

const css = bemBuilder("inbox-send-product");

interface IMenu {
  categories: {
    id: string;
    name: string;
    items: IItem[];
  }[];
}
interface IItem {
  id: string;
  name: string;
  photo: string;
  isModififer: boolean;
  amount: number;
  hidden: boolean;
}
export default defineComponent({
  name: "InboxSendProduct",
  components: {
    MolLoadingZone,
    MolSearchBox,
    Money,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    onSelect: {
      type: Function as PropType<
        (id: string, name: string, image?: string) => void
      >,
      required: true,
    },
  },
  setup(props) {
    const menu = ref<IMenu>();
    const search = ref("");
    const loading = ref(false);

    async function fetchMenu() {
      loading.value = true;
      const activeResource = await fetchActiveMenu(props.businessId);
      menu.value = (await Menus.find(
        props.businessId,
        activeResource.id
      )) as IMenu;
      loading.value = false;
    }

    function selectItem(id: string, name: string, image?: string) {
      props.onSelect(id, name, image);
    }

    const items = computed<IItem[]>(() => {
      if (!menu.value?.categories) {
        return [];
      }

      const allItems = flatten(pluck("items", menu.value.categories)).map(
        (item) => ({
          ...item,
          hidden: false,
        })
      );

      if (!search.value) {
        return allItems;
      }

      return allItems.map((item) => {
        return {
          ...item,
          hidden: !item.name.toLowerCase().includes(search.value.toLowerCase()),
        };
      });
    });

    onMounted(() => {
      fetchMenu();
    });

    return {
      css,
      t,
      search,
      items,
      loading,
      selectItem,
    };
  },
});
