




















































import {
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  AtomText,
  AtomTextTypeEnum,
  AtomTextTagEnum,
  AtomIcon,
} from "@/v2/new-design-system";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  onMounted,
  PropType,
  ref,
} from "@vue/composition-api";
import {
  fetchTemplates,
  IFetchTemplate,
} from "@/v2/repo/inbox/fetch-templates";
import { t } from "@/i18n";

const css = bemBuilder("inbox-select-message-template");

export default defineComponent({
  name: "InboxSelectMessageTemplate",
  components: {
    AtomButton,
    AtomText,
    AtomIcon,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    onSelected: {
      type: Function as PropType<(templatedId: string) => void>,
      required: true,
    },
    onCancel: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const messages = ref<IFetchTemplate[]>([]);
    const selectedMessage = ref<IFetchTemplate>();

    function onSelectOption(option: IFetchTemplate) {
      selectedMessage.value = option;
    }

    function onSend() {
      if (!selectedMessage.value) return;
      props.onSelected(selectedMessage.value.id);
    }

    onMounted(async () => {
      messages.value = await fetchTemplates(props.businessId);
    });

    return {
      t,
      css,
      messages,
      selectedMessage,
      onSelectOption,
      onSend,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
      AtomTextTypeEnum,
      AtomTextTagEnum,
    };
  },
});
