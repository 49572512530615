











import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent } from "@vue/composition-api";
import { t } from "@/i18n";
import { AtomLink, AtomLinkSizeEnum } from "@/v2/new-design-system";

const css = bemBuilder("inbox-message-document");

export default defineComponent({
  name: "InboxMessageDocument",
  components: {
    AtomLink,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    filename: {
      type: String,
      default: undefined,
    },
  },
  setup(props) {
    const label = computed(
      () => props.filename || t("module.inbox.message_document_placeholder")
    );

    return {
      css,
      label,
      AtomLinkSizeEnum,
    };
  },
});
