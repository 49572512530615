


























import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import {
  AtomGraphic,
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
  MolGuideLink,
  MolGuideLinkArticleEnum,
  MolGuideLinkTypeEnum,
} from "@/v2/new-design-system";
import { t } from "@/i18n";
import { IInboxChannel } from "../domain/channel";
import { InboxSendMessageTemplate } from "../send-message-template";

const css = bemBuilder("inbox-compose-locked");

export default defineComponent({
  name: "InboxComposeLocked",
  components: {
    AtomGraphic,
    AtomText,
    MolGuideLink,
    InboxSendMessageTemplate,
  },
  props: {
    channel: {
      type: String as PropType<IInboxChannel>,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
    onSendMessageTemplate: {
      type: Function as PropType<(templateId: string) => void>,
      required: true,
    },
  },
  setup(props) {
    const isWhatsApp = computed(() => props.channel === IInboxChannel.WHATSAPP);

    const readableChannel = computed(() => {
      if (isWhatsApp.value) {
        return "WhatsApp";
      }

      return "Instagram";
    });

    return {
      css,
      t,
      isWhatsApp,
      readableChannel,
      AtomTextColorEnum,
      MolGuideLinkArticleEnum,
      AtomTextTypeEnum,
      MolGuideLinkTypeEnum,
    };
  },
});
