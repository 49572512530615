








import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import { AtomText, AtomTextColorEnum } from "@/v2/new-design-system";
import { t } from "@/i18n";

const css = bemBuilder("inbox-empty-chat");

export default defineComponent({
  name: "InboxEmptyChat",
  components: {
    AtomText,
  },
  setup() {
    return { css, t, AtomTextColorEnum };
  },
});
