





















import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  getCurrentInstance,
  PropType,
  ref,
} from "@vue/composition-api";
import {
  AtomModal,
  MolIconButton,
  MolIconButtonTypeEnum,
} from "@/v2/new-design-system";
import { InboxSendProduct } from "../send-product";
import { inboxTrack } from "../track";
import { formatLinkForChannel } from "@/v2/util";
import { EventEnum } from "@/v2/enum";
import { t } from "@/i18n";
import { IInboxChannel } from "../domain/channel";

const css = bemBuilder("inbox-send-product-button");

export default defineComponent({
  name: "InboxSendProductButton",
  components: {
    AtomModal,
    MolIconButton,
    InboxSendProduct,
  },
  props: {
    onSendProduct: {
      type: Function as PropType<(text: string) => void>,
      required: true,
    },
    channel: {
      type: String as PropType<IInboxChannel>,
      required: true,
    },
    orderingLink: {
      type: String,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const vm = getCurrentInstance();
    const isSendProductModalVisible = ref(false);

    function onItemSelected(id: string, name: string) {
      const message = `${name}\n${formatLinkForChannel(
        props.orderingLink,
        props.channel
      )}/items/${id}`;

      props.onSendProduct(message);

      isSendProductModalVisible.value = false;

      inboxTrack({
        // @ts-ignore
        analytics: vm?.proxy.$analytics,
        eventName: EventEnum.CLICKED_ON_BUTTON,
        additionalData: {
          label: "Send a product",
        },
      });
    }

    return {
      css,
      t,
      onItemSelected,
      isSendProductModalVisible,
      MolIconButtonTypeEnum,
    };
  },
});
