

































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType } from "@vue/composition-api";
import { InboxConversationCard } from "../conversation-card";
import { IInboxContact } from "../domain/contact";
import { IInboxConversation } from "../domain/conversation";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomTextTagEnum,
  AtomIcon,
} from "@/v2/new-design-system";
import { t } from "@/i18n";

const css = bemBuilder("inbox-conversation-list");

export default defineComponent({
  name: "InboxConversationList",
  components: {
    InboxConversationCard,
    AtomText,
    AtomIcon,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    conversations: {
      type: Array as PropType<IInboxConversation[]>,
      default: () => [],
    },
  },
  setup() {
    const getTitle = (contact: IInboxContact): string => {
      return (
        contact.name ??
        contact.phoneNumber ??
        contact.instagramHandle ??
        contact.email ??
        contact.id
      );
    };

    return {
      css,
      t,
      getTitle,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomTextTagEnum,
    };
  },
});
