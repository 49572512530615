




























import { bemBuilder } from "@/v2/util/bem-builder";
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from "@vue/composition-api";
import {
  AtomText,
  AtomTextColorEnum,
  AtomTextTagEnum,
  AtomTextTypeEnum,
} from "@/v2/new-design-system";
import EmblaCarousel, {
  EmblaCarouselType,
  EmblaOptionsType,
} from "embla-carousel";
import { useWindowSize } from "@/v2/composable/use-window-size";
import { t } from "@/i18n";

const css = bemBuilder("inbox-features-display");

export default defineComponent({
  name: "InboxFeaturesDisplay",
  components: {
    AtomText,
  },
  setup() {
    const carouselElement = ref<HTMLDivElement>();
    const embla = ref<EmblaCarouselType>();

    const elements = computed(() => [
      {
        image: require("./assets/launch-marketing-campaigns.png"),
        title: t("module.inbox.features_display.feat_3_title"),
        subtitle: t("module.inbox.features_display.feat_3_subtitle"),
      },
      {
        image: require("./assets/grow-your-contact-list.png"),
        title: t("module.inbox.features_display.feat_1_title"),
        subtitle: t("module.inbox.features_display.feat_1_subtitle"),
      },
      {
        image: require("./assets/be-where-your-customers-are.png"),
        title: t("module.inbox.features_display.feat_2_title"),
        subtitle: t("module.inbox.features_display.feat_2_subtitle"),
      },
    ]);

    const { height, width, onWindowResize, offAllWindowResize } =
      useWindowSize();

    const emblaOptions = computed<EmblaOptionsType>(() => {
      let slidesToScroll = 3;

      if (width.value <= 900) {
        slidesToScroll = 1;
      }

      return {
        loop: true,
        slidesToScroll,
        skipSnaps: true,
        align: 0,
      };
    });

    onWindowResize(() => {
      if (!embla.value) return;
      embla.value.reInit(emblaOptions.value);
    });

    onMounted(() => {
      if (carouselElement.value) {
        embla.value = EmblaCarousel(carouselElement.value, emblaOptions.value);
      }
    });

    onBeforeUnmount(() => {
      offAllWindowResize();
    });

    return {
      css,
      height,
      width,
      carouselElement,
      elements,
      AtomTextColorEnum,
      AtomTextTagEnum,
      AtomTextTypeEnum,
    };
  },
});
