import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";
import { http, IHttpErrorResponse } from "../http";
import { RepoErrorCustomerEnum } from "../repo-error.enum";

export class FetchCustomerProfileError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchCustomerProfile");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export interface IFetchCustomerProfileResponse {
  id: string;
  name: string;
}

export async function fetchCustomerProfile(
  businessId: string,
  phoneNumber: string
): Promise<IFetchCustomerProfileResponse> {
  let response: AxiosResponse;

  const rawPhoneNumber = phoneNumber.replace(/\D/g, "");

  try {
    response = await http.get(
      `/api/v3/businesses/${businessId}/inbox/customer-by-phone/+${rawPhoneNumber}`
    );
  } catch (err: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(err.isAxiosError);

    if (!isAxiosError) {
      throw err;
    }

    const response: IHttpErrorResponse = err;

    switch (response.response?.status) {
      case 404:
        error.errorCode = RepoErrorCustomerEnum.NOT_FOUND;
        break;
      case 400:
        error.errorCode = RepoErrorCustomerEnum.INVALID_PHONE_NUMBER;
        break;
      default:
        error.networkError = true;
    }

    throw new FetchCustomerProfileError(error);
  }

  return {
    id: response.data.id,
    name: response.data.name,
  };
}
