


























import { useHelpers } from "@/v2/composable/use-helpers";
import { AtomLoading } from "@/v2/new-design-system";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  ref,
  watch,
} from "@vue/composition-api";
import { InboxChat } from "../chat";
import { InboxProfile } from "../profile";
import { IInboxConversationWithMessages } from "../domain/conversation";
import { useSocket } from "../socket";
import { sortAscBy } from "@/v2/util";

const css = bemBuilder("inbox-conversation-view");

export default defineComponent({
  name: "InboxConversationView",
  components: { AtomLoading, InboxProfile, InboxChat },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const business = computed(() =>
      useHelpers().getCurrentBusiness(props.businessId)
    );
    const conversation = ref<IInboxConversationWithMessages>();
    const isLoadingConversation = ref(false);
    const hasMoreMessagesToLoad = ref(true);

    function onLoadMoreMessages() {
      const currentLength = conversation.value?.messages.length ?? 0;
      emitEvent("LOAD_MESSAGES", {
        businessId: props.businessId,
        conversationId: props.id,
        rangeStart: currentLength,
        rangeEnd: currentLength + 15,
      });
    }

    const {
      emitEvent,
      onEvent,
      offEvent,
      wsConversationWithMessagesAdapter,
      wsIncomingMessageAdapter,
      wsMessageAdapter,
    } = useSocket();

    function handleMessageReceived(data: Record<string, any>) {
      const message = wsIncomingMessageAdapter(data);

      if (message.conversation.id !== props.id || !conversation.value) return;

      conversation.value = {
        ...conversation.value,
        lastMessageReceivedAt: message.conversation.lastMessageReceivedAt,
      };

      conversation.value.messages.push({
        id: message.id,
        content: message.content,
        authorType: message.authorType,
        createdAt: message.createdAt,
        type: message.type,
        filename: message?.filename,
        coordinates: message?.coordinates,
      });
    }
    onEvent("MESSAGE_RECEIVED", handleMessageReceived);

    function handleMessageDeleted(data: {
      messageId: string;
      conversationId: string;
    }) {
      if (props.id !== data.conversationId || !conversation.value) return;

      const newConversation = conversation.value;
      newConversation.messages = newConversation.messages.filter(
        (m) => m.id !== data.messageId
      );

      conversation.value = newConversation;
    }
    onEvent("MESSAGE_DELETED", handleMessageDeleted);

    function handleMessages(data: Record<string, any>[]) {
      if (!conversation.value) return;

      const messages = data.map((m) => wsMessageAdapter(m));
      const newMessages = [...conversation.value.messages, ...messages];

      if (messages.length === 0) {
        hasMoreMessagesToLoad.value = false;
      }

      conversation.value = {
        ...conversation.value,
        ...{
          messages: sortAscBy(newMessages, "createdAt"),
        },
      };
    }
    onEvent("MESSAGES_LOADED", handleMessages);

    function handleConversation(data: Record<string, any>) {
      conversation.value = wsConversationWithMessagesAdapter(data);
    }
    onEvent("CONVERSATION_LOADED", handleConversation);

    watch(
      () => props.id,
      (newVal, oldVal) => {
        if (newVal && newVal !== oldVal) {
          conversation.value = undefined;
          isLoadingConversation.value = true;
          emitEvent("LOAD_CONVERSATION", {
            businessId: props.businessId,
            conversationId: props.id,
            messagesCount: 40,
          });
        }
      },
      { immediate: true }
    );

    function onProfileReady() {
      isLoadingConversation.value = false;
    }

    function onSendMessage(message: string) {
      emitEvent("SEND_MESSAGE", {
        businessId: props.businessId,
        conversationId: props.id,
        content: message,
        createdAt: new Date(),
      });
    }

    function onSendMessageTemplate(templateId: string) {
      emitEvent("SEND_MESSAGE_TEMPLATE", {
        businessId: props.businessId,
        conversationId: props.id,
        templateId,
        createdAt: new Date(),
      });
    }

    onBeforeUnmount(() => {
      offEvent("MESSAGE_DELETED", handleMessageDeleted);
      offEvent("MESSAGE_RECEIVED", handleMessageReceived);
      offEvent("MESSAGES_LOADED", handleMessages);
      offEvent("CONVERSATION_LOADED", handleConversation);
    });

    const contactPhoto = computed(
      () => conversation.value?.contact.photo ?? ""
    );

    const businessLogo = computed(() => business.value.logo ?? "");

    return {
      css,
      business,
      conversation,
      contactPhoto,
      businessLogo,
      isLoadingConversation,
      hasMoreMessagesToLoad,
      onLoadMoreMessages,
      onSendMessage,
      onSendMessageTemplate,
      onProfileReady,
    };
  },
});
