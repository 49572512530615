








































import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import {
  AtomButton,
  AtomButtonSizeEnum,
  AtomAutoSizeTextarea,
} from "@/v2/new-design-system";
import { InboxSendOrderingLinkButton } from "../send-ordering-link-button";
import { InboxSendMessageTemplate } from "../send-message-template";
import { InboxSendProductButton } from "../send-product-button";
import { t } from "@/i18n";
import { IInboxChannel } from "../domain/channel";

const css = bemBuilder("inbox-compose");

export default defineComponent({
  name: "InboxCompose",
  components: {
    AtomButton,
    AtomAutoSizeTextarea,
    InboxSendOrderingLinkButton,
    InboxSendProductButton,
    InboxSendMessageTemplate,
  },
  props: {
    isGraceTimeGone: {
      type: Boolean,
      default: false,
    },
    channel: {
      type: String as PropType<IInboxChannel>,
      required: true,
    },
    orderingLink: {
      type: String,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
    onSendMessage: {
      type: Function as PropType<(message: string) => void>,
      required: true,
    },
    onSendMessageTemplate: {
      type: Function as PropType<(templateId: string) => void>,
      required: true,
    },
  },
  setup(props) {
    const content = ref<string>("");
    const isWhatsApp = computed(() => props.channel === IInboxChannel.WHATSAPP);

    function appendContent(message: string) {
      const prefix = content.value.length > 0 ? "\n" : "";
      content.value += prefix + message;
    }

    function onSubmit() {
      if (!content.value.length) return;
      props.onSendMessage(content.value);
      content.value = "";
    }

    function onContentInput(input: string) {
      content.value = input;
    }

    function handleEnter(e: KeyboardEvent) {
      e.preventDefault();
      onSubmit();
    }

    return {
      css,
      t,
      onSubmit,
      onContentInput,
      appendContent,
      isWhatsApp,
      handleEnter,
      content,
      AtomButtonSizeEnum,
    };
  },
});
