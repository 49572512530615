import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";
import { http, IHttpErrorResponse } from "../http";
import { RepoErrorCustomerEnum } from "../repo-error.enum";

export class FetchCustomerLtvError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchCustomerLtv");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export interface IFetchCustomerLtvResponse {
  amountOfOrders: number;
  totalRevenue: number;
  firstOrderAt: string;
}

/**
 * Fetch the lifetime value for the incoming customer
 * within the selected business
 * LTV: lifetime value
 */
export async function fetchCustomerLtv(
  businessId: string,
  customerId: string
): Promise<IFetchCustomerLtvResponse> {
  let response: AxiosResponse;

  try {
    response = await http.get(
      `/api/v3/businesses/${businessId}/inbox/customers/${customerId}/lifetime`
    );
  } catch (err: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(err.isAxiosError);

    if (!isAxiosError) {
      throw err;
    }

    const response: IHttpErrorResponse = err;

    switch (response.status) {
      case 404:
        error.errorCode = RepoErrorCustomerEnum.NOT_FOUND;
        break;
      case 403:
        error.errorCode = RepoErrorCustomerEnum.BUSINESS_NOT_ALLOWED;
        break;
      default:
        error.networkError = true;
    }

    throw new FetchCustomerLtvError(error);
  }

  return {
    amountOfOrders: response.data.orders_placed,
    totalRevenue: response.data.total_revenue,
    firstOrderAt: response.data.first_ordered_at,
  };
}
