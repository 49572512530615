import { render, staticRenderFns } from "./FeaturesDisplay.vue?vue&type=template&id=4830077b&scoped=true&"
import script from "./FeaturesDisplay.vue?vue&type=script&lang=ts&"
export * from "./FeaturesDisplay.vue?vue&type=script&lang=ts&"
import style0 from "./FeaturesDisplay.vue?vue&type=style&index=0&id=4830077b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4830077b",
  null
  
)

export default component.exports