






























































import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { IInboxAuthorType } from "../domain/author-type";
import { IInboxChannel } from "../domain/channel";
import { InboxMessageAudio } from "../message-audio";
import { InboxMessageImage } from "../message-image";
import { InboxMessageVideo } from "../message-video";
import { InboxMessageLocation } from "../message-location";
import { InboxMessageDocument } from "../message-document";
import {
  AtomAvatar,
  AtomAvatarSizeEnum,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomTextTagEnum,
} from "@/v2/new-design-system";
import dayjs from "dayjs";
import { IInboxMessageType } from "../domain/message-type";

const css = bemBuilder("inbox-message");

export default defineComponent({
  name: "InboxMessage",
  components: {
    AtomAvatar,
    AtomText,
    InboxMessageAudio,
    InboxMessageImage,
    InboxMessageVideo,
    InboxMessageLocation,
    InboxMessageDocument,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String as PropType<IInboxMessageType>,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    authorType: {
      type: String as PropType<IInboxAuthorType>,
      required: true,
    },
    createdAt: {
      type: Date,
      required: true,
    },
    channel: {
      type: String as PropType<IInboxChannel>,
      required: true,
    },
    imageLink: {
      type: String,
      default: "",
    },
    coordinates: {
      type: Object as PropType<{ lat: number; lng: number }>,
      default: undefined,
    },
    filename: {
      type: String,
      default: undefined,
    },
  },
  setup(props) {
    const isContact = computed(
      () => props.authorType === IInboxAuthorType.CONTACT
    );

    const formattedCreatedAt = computed(() =>
      dayjs(props.createdAt).format("HH:mm")
    );

    return {
      css,
      isContact,
      formattedCreatedAt,
      IInboxMessageType,
      AtomAvatarSizeEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomTextTagEnum,
    };
  },
});
