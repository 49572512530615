











import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import { t } from "@/i18n";
import {
  AtomLink,
  AtomLinkTypeEnum,
  AtomLinkSizeEnum,
} from "@/v2/new-design-system";

const css = bemBuilder("inbox-message-location");

export default defineComponent({
  name: "InboxMessageLocation",
  components: {
    AtomLink,
  },
  props: {
    lat: {
      type: Number,
      required: true,
    },
    lng: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      css,
      t,
      AtomLinkTypeEnum,
      AtomLinkSizeEnum,
    };
  },
});
