



































































































































































import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import { InboxOrderCard } from "../order-card";
import { IInboxConversation } from "../domain/conversation";
import {
  AtomAvatar,
  AtomAvatarSizeEnum,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomTextTagEnum,
  AtomButtonLink,
  AtomButtonLinkTypeEnum,
  AtomButtonLinkSizeEnum,
  AtomLink,
  AtomLinkTypeEnum,
  AtomLinkSizeEnum,
  AtomDate,
  AtomDateTypeEnum,
  AtomMoney,
  AtomAttentionBadge,
  AtomAttentionBadgeTypeEnum,
} from "@/v2/new-design-system";
import {
  fetchCustomerProfile,
  FetchCustomerProfileError,
  IFetchCustomerProfileResponse,
} from "@/v2/repo/fetch-customer-profile";
import {
  fetchCustomerRewards,
  FetchCustomerRewardsError,
  IFetchCustomerRewardsResponse,
} from "@/v2/repo/fetch-customer-rewards";
import {
  fetchCustomerPastOrders,
  FetchCustomerPastOrdersError,
  IFetchCustomerPastOrder,
} from "@/v2/repo/fetch-customer-past-orders";
import {
  fetchCustomerLtv,
  FetchCustomerLtvError,
  IFetchCustomerLtvResponse,
} from "@/v2/repo/fetch-customer-ltv";
import { useHelpers } from "@/v2/composable/use-helpers";
import { OrderingServiceOutletEnum, OrderStatusEnum } from "@/v2/enum";
import { t, tc } from "@/i18n";
import { RepoErrorCustomerEnum } from "@/v2/repo/repo-error.enum";
import { getDayTimeRange } from "@/v2/util";
import dayjs from "dayjs";
import { parsePhoneNumber, getNumberFrom } from "awesome-phonenumber";
import { report } from "@chatfood/bug-reporter";

const css = bemBuilder("inbox-profile");

export default defineComponent({
  name: "InboxProfile",
  components: {
    InboxOrderCard,
    AtomAvatar,
    AtomText,
    AtomButtonLink,
    AtomLink,
    AtomDate,
    AtomMoney,
    AtomAttentionBadge,
  },
  props: {
    conversation: {
      type: Object as PropType<IInboxConversation>,
      required: true,
    },
    onReady: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const customer = ref<IFetchCustomerProfileResponse>();
    const rewardsBalance = ref<IFetchCustomerRewardsResponse>();
    const orders = ref<IFetchCustomerPastOrder[]>([]);
    const ltv = ref<IFetchCustomerLtvResponse>();

    const business = computed(() => {
      return useHelpers().getCurrentBusiness(props.conversation.businessId);
    });

    const formattedPhoneNumber = computed(() => {
      if (!props.conversation.contact.phoneNumber) {
        return "";
      }

      const phoneNumber = parsePhoneNumber(
        `+${props.conversation.contact.phoneNumber}`
      );

      if (phoneNumber.valid) {
        return getNumberFrom(phoneNumber, "international").number;
      }

      return props.conversation.contact.phoneNumber;
    });

    async function fetchRewards(customerId: string) {
      try {
        rewardsBalance.value = await fetchCustomerRewards(
          props.conversation.businessId,
          customerId
        );
      } catch (e) {
        const isRepoError = e instanceof FetchCustomerRewardsError;
        if (!isRepoError) {
          report(e);
        }
      }
    }

    async function fetchPastOrders(customerId: string) {
      try {
        orders.value = await fetchCustomerPastOrders(
          props.conversation.businessId,
          customerId
        );
      } catch (e) {
        const isRepoError = e instanceof FetchCustomerPastOrdersError;
        if (!isRepoError) {
          report(e);
        }
      }
    }

    async function fetchLtv(customerId: string) {
      try {
        ltv.value = await fetchCustomerLtv(
          props.conversation.businessId,
          customerId
        );
      } catch (e) {
        const isRepoError = e instanceof FetchCustomerLtvError;
        if (!isRepoError) {
          report(e);
        }
      }
    }

    async function loadAsyncResources() {
      if (!props.conversation.contact.phoneNumber) {
        props.onReady();
        return;
      }

      try {
        customer.value = await fetchCustomerProfile(
          props.conversation.businessId,
          props.conversation.contact.phoneNumber
        );
      } catch (e: any) {
        const isRepoError = e instanceof FetchCustomerProfileError;

        if (
          !isRepoError ||
          ![
            RepoErrorCustomerEnum.NOT_FOUND,
            RepoErrorCustomerEnum.INVALID_PHONE_NUMBER,
            400,
            // @ts-expect-error the errorCode from the repo is not typed
          ].includes(e.errors.errorCode)
        ) {
          report(e);
        }
      }

      if (!customer.value) {
        props.onReady();
        return;
      }

      await Promise.all([
        fetchPastOrders(customer.value.id),
        fetchLtv(customer.value.id),
        fetchRewards(customer.value.id),
      ]);

      props.onReady();
    }

    const latestOrder = computed(() => orders.value?.[0]);
    const remainingOrders = computed(() => orders.value.slice(1));

    loadAsyncResources();

    function isOrderCancelled(status: string) {
      return status === OrderStatusEnum.CANCELLED;
    }

    function getOrderCancelledLabel(orderingMode: OrderingServiceOutletEnum) {
      const map = {
        [OrderingServiceOutletEnum.DELIVERY]: t("delivery_status.cancelled"),
        [OrderingServiceOutletEnum.PICKUP]: t("pickup_status.cancelled"),
        [OrderingServiceOutletEnum.DINE_IN]: t("dine_in_status.cancelled"),
      };

      return map[orderingMode];
    }

    const monthDateRange = [
      dayjs().subtract(1, "month").toJSON(),
      dayjs().toJSON(),
    ];

    return {
      css,
      t,
      tc,
      business,
      ltv,
      orders,
      rewardsBalance,
      latestOrder,
      remainingOrders,
      isOrderCancelled,
      getOrderCancelledLabel,
      getDayTimeRange,
      monthDateRange,
      formattedPhoneNumber,
      AtomAvatarSizeEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomTextTagEnum,
      AtomButtonLinkTypeEnum,
      AtomButtonLinkSizeEnum,
      AtomLinkTypeEnum,
      AtomLinkSizeEnum,
      AtomDateTypeEnum,
      AtomAttentionBadgeTypeEnum,
    };
  },
});
