import { render, staticRenderFns } from "./MessageAudio.vue?vue&type=template&id=6a3e3aef&scoped=true&"
import script from "./MessageAudio.vue?vue&type=script&lang=ts&"
export * from "./MessageAudio.vue?vue&type=script&lang=ts&"
import style0 from "./MessageAudio.vue?vue&type=style&index=0&id=6a3e3aef&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a3e3aef",
  null
  
)

export default component.exports