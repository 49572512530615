import { render, staticRenderFns } from "./EmptyChat.vue?vue&type=template&id=d2f26184&scoped=true&"
import script from "./EmptyChat.vue?vue&type=script&lang=ts&"
export * from "./EmptyChat.vue?vue&type=script&lang=ts&"
import style0 from "./EmptyChat.vue?vue&type=style&index=0&id=d2f26184&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2f26184",
  null
  
)

export default component.exports