import { graphql } from "../graphql";
import query from "./query.gql";
import { RepoErrorEnum } from "../repo-error.enum";

export async function fetchActiveMenu(
  businessId: string
): Promise<IFetchActiveMenuResponse> {
  try {
    const response = await graphql.query({
      query,
      variables: {
        businessId,
      },
    });

    return response.data.business.menus.find(
      (menu: any) => menu.state === "LIVE"
    );
  } catch {
    throw Error(RepoErrorEnum.NETWORK_ERROR);
  }
}

export interface IFetchActiveMenuResponse {
  id: string;
  name: string;
  state: string;
  categories: Array<Category>;
}

interface Item {
  id: string;
  name: string;
  amount: number;
}

interface Category {
  id: string;
  name: string;
  items: Array<Item>;
}
