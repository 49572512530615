import { EventCategoryEnum } from "@/v2/enum/events";
import {
  ITrackEvent,
  trackEvent,
  analyticsEventCommonData,
} from "@/v2/util/analytics/trackEvent";

function commonData(businessId: string | undefined): Record<string, any> {
  if (!businessId) {
    return {};
  }

  return { ...analyticsEventCommonData([["business", businessId]]) };
}

export function inboxTrack({
  analytics,
  eventName,
  businessId,
  additionalData,
}: ITrackEvent & { businessId?: string }): void {
  trackEvent({
    eventCategory: EventCategoryEnum.INBOX,
    analytics,
    eventName,
    additionalData: {
      ...commonData(businessId),
      ...additionalData,
    },
  });
}
