import { httpInbox } from "../http-inbox";
import {
  IAxiosErrorResponse,
  IRepoInboxErrorResponse,
  RepoInboxError,
  RepoInboxErrorEnum,
} from "../error-response";

export class StartWhatsAppOnboardingError extends RepoInboxError {}

export async function startWhatsAppOnboarding(
  businessId: string,
  businessName: string,
  accessToken: string,
  requestedBy: string
): Promise<boolean> {
  const payload = {
    businessId,
    businessName,
    requestedBy,
    fbUserAccessToken: accessToken,
  };

  try {
    await httpInbox.post(`/whatsapp/start-onboarding`, payload);
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const response: IAxiosErrorResponse = e.response;
    const rawFacebookErrorMessage = response.data?.error?.message;

    const error: IRepoInboxErrorResponse = {
      code: RepoInboxErrorEnum.WHATSAPP_START_ONBOARDING_FAILED,
      message: "The request to start onboarding on WhatsApp has failed.",
      request: payload,
      response: response.data,
    };

    if (rawFacebookErrorMessage) {
      error.code = RepoInboxErrorEnum.WHATSAPP_START_ONBOARDING_FAILED;
      error.message = rawFacebookErrorMessage;

      throw new StartWhatsAppOnboardingError(error);
    }

    throw new StartWhatsAppOnboardingError(error);
  }

  return true;
}
