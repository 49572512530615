































































































import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomTextTagEnum,
  AtomButtonLink,
  AtomButtonLinkSizeEnum,
  AtomDate,
  AtomDateTypeEnum,
  AtomLink,
  AtomLinkTypeEnum,
  AtomLinkSizeEnum,
  AtomIcon,
  AtomMoney,
  AtomPaymentMethod,
} from "@/v2/new-design-system";
import { IFetchCustomerPastOrder } from "@/v2/repo/fetch-customer-past-orders";
import {
  OrderStatusEnum,
  OrderingServiceOutletEnum,
  CurrencyCodeEnum,
  PaymentMethodEnum,
} from "@/v2/enum";
import { RawLocation } from "vue-router";
import { InboxOrderCardStatusLine } from "../order-card-status-line";
import { t } from "@/i18n";
import { getDayTimeRange, getTranslatedOrderStatus } from "@/v2/util";

const css = bemBuilder("inbox-order-card");

export default defineComponent({
  name: "InboxOrderCard",
  components: {
    AtomText,
    AtomDate,
    AtomLink,
    AtomButtonLink,
    AtomIcon,
    AtomMoney,
    InboxOrderCardStatusLine,
    AtomPaymentMethod,
  },
  props: {
    order: {
      type: Object as PropType<IFetchCustomerPastOrder>,
      required: true,
    },
    currency: {
      type: String as PropType<CurrencyCodeEnum>,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLiveOrder = computed(() => {
      return [
        OrderStatusEnum.CREATED,
        OrderStatusEnum.PLACED,
        OrderStatusEnum.ACCEPTED,
        OrderStatusEnum.READY,
      ].includes(props.order.status);
    });

    const orderLink = computed<RawLocation>(() => {
      if (isLiveOrder.value) {
        return {
          name: "orders.live.view",
          params: {
            orderId: props.order.id,
            businessId: props.businessId,
          },
        };
      }

      return {
        name: "orders.index",
        query: {
          orderUid: props.order.uid,
          createdAt: [
            getDayTimeRange(props.order.placedAt)[0].toJSON(),
            getDayTimeRange(props.order.placedAt)[1].toJSON(),
          ],
        },
      };
    });

    const title = computed(() => {
      return isLiveOrder.value
        ? t("module.inbox.order_card.live_order_title")
        : t("module.inbox.order_card.past_order_title");
    });

    interface IAvailableStatus {
      value: string;
      label: string;
    }

    const availableStatuses = computed<IAvailableStatus[]>(() => {
      const statusesMap: Record<string, OrderStatusEnum> = {
        placedAt: OrderStatusEnum.PLACED,
        acceptedAt: OrderStatusEnum.ACCEPTED,
        readyAt: OrderStatusEnum.READY,
        completeAt: OrderStatusEnum.COMPLETED,
        rejectedAt: OrderStatusEnum.REJECTED,
        cancelledAt: OrderStatusEnum.CANCELLED,
      };

      const results: IAvailableStatus[] = [];

      Object.keys(statusesMap).forEach((key) => {
        const statusValue = props.order[
          key as keyof IFetchCustomerPastOrder
        ] as string;
        if (!statusValue) return;

        results.push({
          value: statusValue,
          label: getTranslatedOrderStatus(
            props.order.orderingService,
            statusesMap[key]
          ),
        });
      });

      return results;
    });

    const isDelivery = computed(
      () => props.order.orderingService === OrderingServiceOutletEnum.DELIVERY
    );

    const isTab = computed(
      () => props.order.paymentMethod === PaymentMethodEnum.TAB
    );

    const isPaid = computed(() => {
      return (
        props.order.paymentMethod === PaymentMethodEnum.PREPAID ||
        props.order.status === OrderStatusEnum.COMPLETED ||
        props.order.paymentMethod === PaymentMethodEnum.APPLE_PAY
      );
    });

    const orderTypeLabel = computed(() => {
      if (props.order.orderingService === OrderingServiceOutletEnum.DINE_IN) {
        return t("module.inbox.order_card.mode_for_dine_in");
      }

      if (props.order.orderingService === OrderingServiceOutletEnum.PICKUP) {
        return t("module.inbox.order_card.mode_for_pickup");
      }

      return t("module.inbox.order_card.mode_for_delivery");
    });

    return {
      css,
      t,
      title,
      orderTypeLabel,
      isDelivery,
      isPaid,
      isTab,
      orderLink,
      availableStatuses,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomTextTagEnum,
      AtomDateTypeEnum,
      AtomLinkTypeEnum,
      AtomLinkSizeEnum,
      AtomButtonLinkSizeEnum,
    };
  },
});
