import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";
import { http, IHttpErrorResponse } from "../http";
import { RepoErrorCustomerEnum } from "../repo-error.enum";

export class FetchCustomerRewardsError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchCustomerRewards");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export interface IFetchCustomerRewardsResponse {
  balance: number;
}

export async function fetchCustomerRewards(
  businessId: string,
  customerId: string
): Promise<IFetchCustomerRewardsResponse> {
  let response: AxiosResponse;

  try {
    response = await http.get(
      `/api/v3/businesses/${businessId}/inbox/customers/${customerId}/reward-points`
    );
  } catch (err: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(err.isAxiosError);

    if (!isAxiosError) {
      throw err;
    }

    const response: IHttpErrorResponse = err;

    switch (response.status) {
      case 403:
        error.errorCode = RepoErrorCustomerEnum.BUSINESS_NOT_ALLOWED;
        break;
      default:
        error.networkError = true;
    }

    throw new FetchCustomerRewardsError(error);
  }

  return {
    balance: response.data.balance,
  };
}
