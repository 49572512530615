import { OrderingServiceOutletEnum, OrderStatusEnum } from "@/v2/enum";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";
import { http, IHttpErrorResponse } from "../http";
import { RepoErrorCustomerEnum } from "../repo-error.enum";

export class FetchCustomerPastOrdersError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchCustomerPastOrders");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export interface IFetchCustomerPastOrder {
  id: string;
  uid: string;
  status: OrderStatusEnum;
  createdAt: string;
  acceptedAt?: string;
  readyAt?: string;
  placedAt: string;
  deliveredAt?: string;
  completeAt?: string;
  rejectedAt?: string;
  cancelledAt?: string;
  deliveryAddress?: {
    location: string;
    lat?: number;
    lng?: number;
  };
  orderingService: OrderingServiceOutletEnum;
  paymentMethod: string;
  outlet: {
    id: string;
    name: string;
  };
  total: number;
}

/**
 * API returns always the past 4 orders only
 */
export async function fetchCustomerPastOrders(
  businessId: string,
  customerId: string
): Promise<Array<IFetchCustomerPastOrder>> {
  let response: AxiosResponse;

  try {
    response = await http.get(
      `/api/v3/businesses/${businessId}/inbox/customers/${customerId}/orders`
    );
  } catch (err: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(err.isAxiosError);

    if (!isAxiosError) {
      throw err;
    }

    const response: IHttpErrorResponse = err;

    switch (response.status) {
      case 403:
        error.errorCode = RepoErrorCustomerEnum.BUSINESS_NOT_ALLOWED;
        break;
      default:
        error.networkError = true;
    }

    throw new FetchCustomerPastOrdersError(error);
  }

  return response.data.map((order: any) => ({
    id: order.id,
    uid: order.uid,
    status: order.status,
    createdAt: order.created_at ?? undefined,
    acceptedAt: order.accepted_at ?? undefined,
    readyAt: order.ready_at ?? undefined,
    placedAt: order.placed_at ?? undefined,
    deliveredAt: order.delivered_at ?? undefined,
    completeAt: order.complete_at ?? undefined,
    rejectedAt: order.rejected_at ?? undefined,
    cancelledAt: order.cancelled_at ?? undefined,
    deliveryAddress: order.delivery_address
      ? {
          location: order.delivery_address.location,
          lat: order.delivery_address.lat,
          lng: order.delivery_address.lng,
        }
      : undefined,
    orderingService: order.ordering_service,
    paymentMethod: order.payment_method,
    outlet: {
      id: order.outlet.id,
      name: order.outlet.name,
    },
    total: order.total,
  }));
}
