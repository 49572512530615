








import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType } from "@vue/composition-api";
import { IInboxChannel } from "../domain/channel";

const css = bemBuilder("inbox-message-audio");

export default defineComponent({
  name: "InboxMessageAudio",
  props: {
    url: {
      type: String,
      required: true,
    },
    channel: {
      type: String as PropType<IInboxChannel>,
      required: true,
    },
  },
  setup() {
    return {
      css,
    };
  },
});
